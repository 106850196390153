<template>
{{ mesText }}
</template>

<script>
import { onMounted } from 'vue'
import moment from 'moment-timezone'

export default{
    name: 'MesComponent',
    props: {
        'date':{
            type: String
        },
        'short':{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mes: '',
            mesesCortos : ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            meses : ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        }
    },
    computed: {
        mesText() {
            const d = new Date()
            if(!this.mes >= 1 && !this.mes <= 12) 
                this.mes = moment(d).tz('UTC').format('M')
            return this.short ? this.mesesCortos[this.mes - 1] : this.meses[this.mes - 1]
        }
    },
    mounted() {
        this.mes = moment(this.date).tz('UTC').format('M')
    }
    
}

</script>