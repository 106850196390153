<template>
{{ semanaText }}
</template>

<script>
import { onMounted } from 'vue';
import moment from 'moment-timezone'

export default{
    name: 'SemanaComponent',
    props: {
        'date':{
            type: String
        }
    },
    data() {
        return {
            semana: '',
            semanas : ['Domingo','Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
        }
    },
    computed: {
        semanaText() {
            const d = new Date()
            if(!this.semana >= 1 && !this.semana <= 7) 
                this.semana = moment(d).tz('UTC').day()
            return this.semanas[this.semana]
        }
    },
    mounted() {
        this.semana = moment(this.date).tz('UTC').day()
    }
    
}

</script>